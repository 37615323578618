import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import * as EmailValidator from 'email-validator';

const ContactPage = () => {
  const [formName, setFormName] = useState('')
  const [formEmail, setFormEmail] = useState('')
  const [formSubject, setFormSubject] = useState('website')
  const [formMessage, setFormMessage] = useState('')

  const [submitted, setSubmitted] = useState(false)
  const [validateFailed, setValidateFailed] = useState(false)

  // const form = document.getElementById("form");
  // if (form !== null) {
  //   form.addEventListener("submit", formSubmit);
  // }

  function formSubmit(e: any) {
    e.preventDefault();

    let validated = validateForm()
    console.log('validated', validated);

    if (validated === false) {
      // alert('Form is not valid.')
      setValidateFailed(true)
      return
    }

    const formData = new FormData(e.target);

    fetch("https://getform.io/f/4fd3e49c-f29c-4a38-824b-e09be2409690", {
      method: "POST",
      body: formData,
      headers: {
        "Accept": "application/json",
      },
    })
      .then(response => {
        console.log('form response:', response)
        setSubmitted(true)
      })
      .catch(error => console.log(error))
  }

  function onValueChangeSubject(event: any) {
    // Updating the state with the selected radio button's value
    // console.log(event.target.value);
    if (event !== null && event.target !== null && event.target.value !== undefined) {
      setFormSubject(event.target.value)
    }
  }

  // function isValidEmail(email: string): boolean {
  //   // Define a regular expression pattern for email validation.
  //   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  //   const isValidEmail = emailRegex.test(email);
  //   return isValidEmail
  // }

  function validateForm(): boolean {

    console.log(formName.length);
    if (formName === '') {
      // alert('Invalid Form, First Name can not be empty')
      return false
    } else if (formName !== '' && formName.length < 2) {
      // alert('Invalid Form, First Name can not be less then 3 characters')
      return false
    }
    if (formEmail === '') {
      // alert('Invalid Form, Email Address can not be empty')
      return false
    } else {
      // let validEmail = isValidEmail(formEmail)
      let validEmail = EmailValidator.validate(formEmail);
      console.log(validEmail);
      if (validEmail === false) {
        return false
      }
    }
    if (formMessage === '' || formMessage.length === 0) {
      return false
    }

    // alert('Form is valid')
    return true
  }

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Contact"
      />

      <section className="max-w-screen-lg m-auto mt-20 mb-10">
        <h1 className="text-center p-3 mt-5 mb-4 text-2xl font-bold text-white">
          Contact ABC Interactive LLC
        </h1>
      </section>
      <section className="w-full max-w-2xl px-6 py-4 mx-auto bg-white rounded-md shadow-md dark:bg-gray-800 mb-10">
        <p className="mt-3 text-center text-gray-600 dark:text-gray-400 font-family-opensans">
          ABC Interactive LLC offers development services and also
          affordable/free educational games that combine learning with fun!
        </p>

        <div className="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 md:grid-cols-3">
          <div
            className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 
            transform rounded-md dark:text-gray-200 "
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" />
            </svg>

            <span className="mt-2">California, USA</span>
          </div>

          <div
            className="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 
            transform rounded-md dark:text-gray-200 hover:bg-blue-200 dark:hover:bg-blue-500"
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>

            <span className="mt-2">(415) 342-3671</span>
          </div>

          <a
            href="mailto:info@abci.io"
            className="
            flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 
            transform rounded-md dark:text-gray-200 hover:bg-blue-200 dark:hover:bg-blue-500
            "
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>

            <span className="mt-2">info@abci.io</span>
          </a>
        </div>
        {!submitted && (
          <form onSubmit={formSubmit}>
            <input type="hidden" name="_gotcha" className="hiddenBlock" />

            <div className="mt-6 ">
              <div className="items-center -mx-2 md:flex">
                <div className={`w-full mx-2 p-2 border rounded-md ${validateFailed === true && (formName === '' || formName.length < 2) ? ' border-red-500' : 'border-white-0'}`}>
                  <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                    Name
                    <input
                      className={`block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 
                    dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 dark:focus:border-blue-300 focus:outline-none 
                    focus:ring focus:ring-opacity-40`}
                      id="full-name"
                      placeholder="name or company"
                      type="text"
                      name="name"
                      onChange={(e) => setFormName(e.target.value)}
                    />
                  </label>

                </div>
                <div className={`w-full mx-2 mt-4 md:mt-0 p-2 border rounded-md ${validateFailed === true && (formEmail === '' || EmailValidator.validate(formEmail) === false) ? ' border-red-500' : 'border-white-100'}`}>
                  <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                    E-mail
                    <input
                      className={`block w-full px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 
                    dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 dark:focus:border-blue-300 
                    focus:outline-none focus:ring focus:ring-opacity-40`}
                      id="email"
                      placeholder="your email address"
                      type="text"
                      name="email"
                      onChange={(e) => setFormEmail(e.target.value)}
                    />
                  </label>

                </div>
              </div>

              <div className="w-full mt-4">
                <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200 p-2 border rounded-md">
                  Subject
                  <div className="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 md:grid-cols-3">
                    <div className="flex flex-col items-center">
                      <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                        Website 
                        <input type="radio" name="subject" value="website" className="m-1" checked={formSubject === "website"} onChange={onValueChangeSubject} />
                      </label>
                    </div>
                    <div className="flex flex-col items-center">
                      <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                        App
                        <input type="radio" name="subject" value="app" className="m-1" checked={formSubject === "app"} onChange={onValueChangeSubject} />
                      </label>
                    </div>
                    <div className="flex flex-col items-center">
                      <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                        Game
                        <input type="radio" name="subject" value="game" className="m-1" checked={formSubject === "game"} onChange={onValueChangeSubject} />
                      </label>
                    </div>
                    <div className="flex flex-col items-center">
                      <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                        SEO
                        <input type="radio" name="subject" value="seo" className="m-1" checked={formSubject === "seo"} onChange={onValueChangeSubject} />
                      </label>
                    </div>
                    <div className="flex flex-col items-center">
                      <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                        Design
                        <input type="radio" name="subject" value="design" className="m-1" checked={formSubject === "design"} onChange={onValueChangeSubject} />
                      </label>
                    </div>
                    <div className="flex flex-col items-center">
                      <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                        Animation
                        <input type="radio" name="subject" value="animation" className="m-1" checked={formSubject === "animation"} onChange={onValueChangeSubject} />
                      </label>
                    </div>
                    <div className="flex flex-col items-center">
                      <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                        3D
                        <input type="radio" name="subject" value="3D" className="m-1" checked={formSubject === "3D"} onChange={onValueChangeSubject} />
                      </label>
                    </div>
                  </div>
                </label>
              </div>
              <div className={`w-full mt-4 p-2 border rounded-md ${validateFailed === true && formMessage === '' ? ' border-red-500' : 'border-white-100'}`}>
                <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                  Message
                  <textarea
                    className="block w-full h-40 px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-800 
              dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring 
              focus:ring-blue-300 focus:ring-opacity-40"
                    id="message"
                    placeholder=""
                    name="message"
                    onChange={(e) => setFormMessage(e.target.value)}
                  ></textarea>
                </label>
              </div>

              {validateFailed && <div className="w-full items-center mt-2 mb-2 text-md text-red-500">Please complete all required fields.</div>}
              <div className="flex justify-center mt-6">
                <button className="px-4 py-2 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                  type="submit">
                  Send Message
                </button>
              </div>
            </div>
          </form>
        )}

        {submitted && (
          <div className="flex flex-col items-center p-10">
            <div className="text-2xl">Thank you!</div>
            <div className="text-md">Your message has been sent.</div>
          </div>
        )}
      </section>
    </Layout>
  )
}

export default ContactPage

{/* <form method="POST" accept-charset="UTF-8" id="form">
    <input type="email" name="email" placeholder="Your email">
    <input type="text" name="full-name" placeholder="Your name">
    <!-- add hidden Honeypot input to prevent spams -->
    <input type="hidden" name="_gotcha" style="display:none !important">
    <button type="submit">Send</button>
</form>

<script>
    const form = document.getElementById("form");
    form.addEventListener("submit", formSubmit);

    function formSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);

        fetch("https://getform.io/f/4fd3e49c-f29c-4a38-824b-e09be2409690", {
            method: "POST",
            body: formData,
            headers: {
                "Accept": "application/json",
            },
        })
        .then(response => console.log(response))
        .catch(error => console.log(error))
    }
</script> */}

{/* <form action="https://getform.io/f/4fd3e49c-f29c-4a38-824b-e09be2409690" method="POST">
    <input type="text" name="name">
    <input type="email" name="email">
    <input type="text" name="message">
    <!-- add hidden Honeypot input to prevent spams -->
    <input type="hidden" name="_gotcha" style="display:none !important">
    <!-- checkbox handle -->
    <input type="checkbox" name="subscribe" value="yes" checked>
    <input type="hidden" name="subscribe" value="no">
    <!-- radio button handle -->
    <input type="radio" name="gender" value="male" checked>
    <input type="radio" name="gender" value="female">
    <input type="radio" name="gender" value="other">
    <!-- select field handle -->
    <select name="work-experience">
        <option value="one-year">0-1 years</option>
        <option value="one-five-years">1-5 years</option>
    </select>
    <button type="submit">Send</button>
</form> */}